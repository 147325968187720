import {
    $, addFilter, addAction, doAction, LAYOUTEND, INIT,
} from '@situation/setdesign.util';

addFilter('content-slider/owl-settings', (settings) => ({
    ...settings,
    items: 1,
    stagePadding: 0,
    navContainer: '.content-slider__arrows',
    navText: [
        '<svg><use xlink:href="#icon-arrow-left"></use></svg>',
        '<svg><use xlink:href="#icon-arrow-right"></use></svg>',
    ],
}));

const owlSettings = {
    margin: 0,
    items: 1,
    dots: false,
    nav: false,
    navText: [
        '<svg><use xlink:href="#icon-arrow-left"></use></svg>',
        '<svg><use xlink:href="#icon-arrow-right"></use></svg>',
    ],
    stagePadding: 20,
    responsive: { 576: { items: 2 }, 768: { items: 3 } },
};
let initialized = false;
addAction(INIT, () => {
    const $rows = $('.content-row--mobile-carousel');
    if (!$rows.length) {
        return;
    }

    function updateSlider() {
        if (SetDesign.viewport.width() < 768 && !initialized) {
            initialized = true;
            $rows.addClass('owl-carousel').owlCarousel(owlSettings);
        } else if (SetDesign.viewport.width() >= 768 && initialized) {
            initialized = false;
            $rows.removeClass('owl-carousel').trigger('destroy.owl.carousel');
        }
    }

    $rows.on('changed.owl.carousel', (e) => {
        if (e.property.name === 'position') {
            doAction('refreshLazyLoad', $(e.currentTarget));
        }
    });

    updateSlider();
    addAction(LAYOUTEND, updateSlider);
});
