import {
    $, applyFilters, addFilter, addAction, READY, INIT,
} from '@situation/setdesign.util';

addAction(READY, () => {
    addFilter('css-vars/register', (styles) => {
        const newStyles = { ...styles };
        newStyles['slant-height'] = () => `${applyFilters('slant-height')}px`;
        return newStyles;
    });
});

addAction(INIT, () => {
    const $firstSVG = $('.content-section__slant-svg').first();
    if ($firstSVG.length) {
        addFilter('slant-height', () => $firstSVG.outerHeight(true) || 0, 5);
    }
});
