import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const SECONDS = 2500;
    let counter = 0;
    $('.animated-heading').each((index, value) => {
        const $this = $(value);
        let words = $this.attr('data-word-cycle') || [];
        if (!words.length) {
            return;
        }

        words = words.split(',').map((s) => s.trim());
        const $text = $this.find('.animated-heading__text');
        // setTimeout(() => {
        setInterval(() => {
            $text.text(words[counter]);
            counter += 1;

            if (counter >= words.length) {
                counter = 0;
            }
        }, SECONDS);
        // }, SECONDS);
    });
});
