import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const $tabsContainer = $('.our-team-tabs');
    if (!$tabsContainer.length) {
        return;
    }

    const CONTENT_COLUMN = '.content-column';
    const SUB_NAV_ITEM_ACTIVE_CLASS = 'sub-nav__item--active';
    const $ourTeamMemberCardRow = $('.our-team-member-card-row');
    const $subNavItems = $tabsContainer.find('.sub-nav__item');
    $subNavItems.first().addClass(SUB_NAV_ITEM_ACTIVE_CLASS);
    $subNavItems.each((index, value) => {
        const $subNavItem = $(value);
        const $subNavLink = $subNavItem.find('.sub-nav__link');
        $subNavLink.click((e) => {
            e.preventDefault();
            const sbuId = $subNavLink.data('sbuId') ? String($subNavLink.data('sbuId')) : false;
            $subNavItems.removeClass(SUB_NAV_ITEM_ACTIVE_CLASS);
            $subNavItem.addClass(SUB_NAV_ITEM_ACTIVE_CLASS);

            if (sbuId) {
                $ourTeamMemberCardRow.find(CONTENT_COLUMN).hide();
                $ourTeamMemberCardRow
                    .find('.content-column')
                    .filter((i, el) => String($(el).data('sbuId')).split(',').indexOf(sbuId) >= 0)
                    .show();
            } else {
                $ourTeamMemberCardRow.find(CONTENT_COLUMN).show();
            }
        });
    });
});
